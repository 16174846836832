/* You can add global styles to this file, and also import other style files */
.sec-title {
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.sec-title h2 {
  font-weight: 600;
}

.sp-txt {
  color: #00b94f;
  font-weight: 600;
}

.about-content-sec {
  padding: 0px 0 10px 0;
}

.about-content-sec p {
  color: #3e3c3c;
  font-weight: 400;
  text-align: justify;
}

.gray-bg {
  background-color: #f7f7f7;
}

.service-box {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
  flex-direction: column;
}

.service-box .top-box {
  display: flex;
}

.service-box .icon-circle {
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.service-box:hover .icon-circle {
  background-color: #0093ff;
}

.service-box .icon-circle i {
  font-size: 26px;
  padding: 17px 0px 0px 20px;
  color: #0093ff;
}

.service-box:hover .icon-circle i {
  color: white;
}

.service-box .service-title {
  padding: 10px;
  width: 80%;
}

.service-title p {
  font-size: 13px;
}

.project-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.project-box .project-details {
  padding: 10px 0 10px 20px;
}

.project-box .project-details p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.project-box .project-details i {
  margin-right: 10px;
}

.project-box .project-details h6 {
  font-size: 20px;
  color: #0093ff;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 10px;
}

.margin-0 {
  margin: 0;
}

.whatsapp-icon-call {
  animation: hithere 1s ease infinite;
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.wapp-link {
  margin-right: 10px;

}

.wapp-link img {
  margin-right: 10px;

}


.dropdown-item {
  padding: 0.25rem 1rem !important;
}

.animate-border {
  position: relative;
  display: block;
  width: 115px;
  height: 3px;
  background: #00b94f;
  overflow: hidden;
  margin: 10px auto 30px auto;
}

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 15px;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

.about-img-sec {
  margin-top: 3rem;
}

.feature-box3 {
  position: relative;
  float: left;
}

.iconbox-xlarge {
  width: 135px;
  height: 135px;
  font-size: 46px;
  color: #242424;
  line-height: 135px;
  background-color: white;
  border-radius: 50%;
  margin: 0 auto;
}

.feature-box3 .iconbox-xlarge {
  position: relative;
  color: #00b94f;
  font-size: 28px;
}

.feature-box3:hover .iconbox-xlarge,
.feature-box3.active .iconbox-xlarge {
  color: #fff;
  background-color: #00b94f;
}

.feature-box3:hover .iconbox-xlarge .digit,
.feature-box3.active .iconbox-xlarge .digit {
  color: #242424;
  background-color: #fff;
}

.feature-box3 p {
  color: #3e3c3c;
  font-weight: 400;
}
.feature-box3 h4 {
  font-weight: 600;
}
.pad-top-2{
  padding-top: 2rem;
}

.pad-top-3{
  padding-top: 3rem;
}

.loader-continer{
  background-color: #000000;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    bottom: 0;
}

.loader {
  margin-top: 20rem;
  margin-left: 45rem;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #00450a;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}